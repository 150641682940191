import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container">
            <div className="row">
                <h2>ACUERDO DE PRIVACIDAD DE APPRENDE ONLINE</h2>
                <p>
                    Cloudsoft Industries identificada con NIT 7001255233 con ubicación en Colombia Armenia Quindio MZ 2 CS 4 BRR Las Acacias - opera la plataforma en línea [apprende.online] (en adelante, el "Sitio") y proporciona servicios de cursos en línea (los "Servicios"). Esta página tiene como objetivo informarte sobre nuestras políticas con respecto a la recopilación, uso y divulgación de la información personal que recibimos de los usuarios del Sitio y los Servicios.
                </p>

                <h3>1. Información que Recopilamos:</h3>
                <p>
                    <strong>1.1 Información de Registro:</strong> Al registrarte en Apprende Online, solicitamos cierta información personal, que puede incluir, entre otros, tu nombres, apellidos, dirección de correo electrónico, Esta información se utilizará para gestionar tu cuenta, procesar pagos y enviarte información relacionada con los cursos.
                </p>
                <p>
                    <strong>1.2 Información del Curso:</strong> Cuando participas en nuestros cursos, recopilamos información sobre tu progreso, calificaciones y actividades dentro de la plataforma. Esto nos ayuda a personalizar tu experiencia y mejorar nuestros servicios.
                </p>
                <p>
                    <strong>1.3 Información Automática:</strong> Al utilizar el Sitio, automáticamente recibimos y registramos información del dispositivo que estás utilizando, incluida tu dirección IP, tipo de navegador y páginas que visitas. Esta información se utiliza para analizar tendencias, administrar el sitio y recopilar información demográfica para uso interno.
                </p>
                <h3>2. Uso de la Información:</h3>
                <p>
                    <strong>2.1 Personalización de Experiencia:</strong> Utilizamos la información recopilada para personalizar tu experiencia en Apprende Online, proporcionarte contenido relevante y mejorar nuestros servicios.
                </p>
                <p>
                    <strong>2.2 Comunicación:</strong> Podemos utilizar tu información de contacto para enviarte comunicaciones relacionadas con tu cuenta, cambios en nuestros servicios o actualizaciones importantes, promociones de cursos, anuncios de cursos nuevos o sencillamente enviarte publicidad relacionada a Apprende Online
                </p>
                <p>
                    <strong>2.3 Análisis y Mejora:</strong> Analizamos la información recopilada para mejorar la funcionalidad del sitio, identificar tendencias y realizar mejoras en nuestros cursos y servicios.
                </p>
                <h3>3. Divulgación de Información:</h3>
                <p>
                    <strong>3.1 Proveedores de Servicios:</strong> Podemos compartir tu información con proveedores de servicios que nos ayudan en la prestación de los Servicios, como procesadores de pagos y plataformas de alojamiento.
                </p>
                <p>
                    <strong>3.2 Cumplimiento Legal:</strong> Podemos divulgar tu información personal cuando lo exija la ley o en respuesta a solicitudes gubernamentales legales.
                </p>
                <h3>4. Seguridad:</h3>
                <p>
                    Implementamos medidas de seguridad para proteger la información personal que recopilamos. Sin embargo, ninguna transmisión por Internet o almacenamiento electrónico es completamente seguro, por lo que no podemos garantizar la seguridad absoluta.
                </p>
                <h3>5. Uso de Wompi como Pasarela de Pagos</h3>
                <p>
                    Utilizamos Wompi como nuestra pasarela de pagos para procesar las transacciones en nuestra plataforma. La información de pago que proporciones, como números de tarjeta de crédito y detalles relacionados, estará bajo la protección de Wompi, una pasarela de pago colombiana reconocida. Wompi implementa medidas de seguridad robustas para garantizar la confidencialidad y seguridad de tu información financiera.
                </p>

                <h3>6. Cambios en la Política de Privacidad:</h3>
                <p>
                    Nos reservamos el derecho de actualizar o modificar esta Política de Privacidad en cualquier momento. Te recomendamos revisar periódicamente esta página para estar al tanto de cualquier cambio.
                </p>

                <h3>7. Contacto:</h3>
                <p>
                    Si tienes preguntas sobre esta Política de Privacidad, puedes contactarnos a través de [jdanigo@cloudsoft.site].
                </p>

                <p>
                    Al registrarte y utilizar nuestros servicios, aceptas los términos y condiciones de esta Política de Privacidad.
                </p>
            </div>

        </div>
    );
};

export default PrivacyPolicy;
